import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  InputAdornment,
  Input,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { GoogleMap,  Marker,  OverlayView } from '@react-google-maps/api';
import { search } from "../../reservations/src/assets";
import { View } from "react-native";
const containerStyle = {
  width: '100%',
  height: '700px',
};
const defaultCenter = {
  lat: 28.6336,
  lng: 77.2050,
};
// Customizable Area End

import LocationController, {
    Props,
    configJSON
  } from "./LocationController";

export default class Location extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  customCircleIcon = {
    path: "M 0,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
  fillColor: "#F5C502",
  fillOpacity: 1,
  strokeColor: "#F5C502",
  strokeOpacity: 0.1,
  strokeWeight: 40,
  scale: 1,
  };
  // Customizable Area End
  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
    <Box sx={webStyle.landingPageView}>
     
      <Box>
      <View style={{ flexDirection: "row", justifyContent: "space-between", shadowColor: '#CBD5E1',
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    elevation: 8,
    borderWidth: 1,
    borderColor: '#CBD5E1',
    borderRadius: 10, alignContent: "center", alignItems: "center", padding: 2, backgroundColor: "white",marginBottom:20}}>
                <Input
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", paddingTop: 1
                  }}
                  disableUnderline
                  data-test-id="txtInputSearch"
                  type={'text'}
                  placeholder={"Search"}
                  // onChange={(text: React.ChangeEvent<HTMLInputElement>) => this.searchFilterFunction(text.target.value)}
                  // value={this.state.searchText}
                  startAdornment={
                    <InputAdornment sx={{ marginBottom: 0.5 }} position="end">
                      <img style={{ height: "21.01px", marginRight: 8 }} src={search.default} />
                    </InputAdornment>
                  }
                />
              </View>
              </Box>
              
              <Box sx={{position:"absolute",top:135,left:20,zIndex:2,backgroundColor:"white",padding:1}}>
                <Typography style={{fontWeight:"700",fontSize:"16px"}}>{configJSON.pin}</Typography>
                {/* <KeyboardArrowDownIcon sx={{color:"black"}}/> */}
                <Select
              data-test-id="serviceTestId"
            sx={{borderRadius:"8px",boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add shadow
             }}
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
                transformOrigin: { vertical: "top", horizontal: "right" },
                PaperProps: {
                  sx: {
                    left: -70,
                    backgroundColor: "transparent", 
                    boxShadow: "none" 
                  },
                },
              }}
            >
              <Grid >
              {this.state.pins.map((item, index) => ( 
                 <MenuItem key={item.name}  data-test-id="pinTestid" sx={{ borderBottom: "1px solid #ddd",backgroundColor:"white",display: "flex", justifyContent: "flex-end", left:60}} onClick={()=>this.sendDataToParent(item)}>
              <Grid style={{borderRadius:"8px",borderColor:"red"}} container flexDirection={"row"} >
                <Grid item xs={2}>
                <img src={item.icon} style={{margin:2,height:"24px",width:"24px"}}></img>
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ whiteSpace: "normal",  
      wordBreak: "break-word", 
      overflowWrap: "break-word", 
      width: "200px", 
      paddingRight: 5}}> {item.name}</Typography>
                </Grid>
             
              </Grid>
            
              </MenuItem>
            ))}
              </Grid>
            </Select>
              </Box>
       <GoogleMap mapContainerStyle={containerStyle} center={{
  lat: this.state.currentLat,
  lng: this.state.currentLang,
}} zoom={40}  onClick={this.handleMapClick}>
   <Marker
          position={{
            lat: this.state.currentLat,
            lng: this.state.currentLang,
          }}
          icon={this.customCircleIcon}
            title={"current location"} 
        />
   <OverlayView
           position={{
                lat: this.state.lat,
                lng: this.state.lng,
              }}
           mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} 
         >
           <div
             style={{
               position: "absolute",
               transform: "translate(-50%, -50%)",
               backgroundColor: "#FFFDE7", 
              width: "60px",
            height: "60px",
               backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "50%", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
             }}
           >
             <img
               src= {this.state.selectedPin.icon} 
               alt="Custom Marker"
               style={{
                 width: "20px",
                 height: "20px",
                 objectFit: "cover",
                
               }}
             />
           </div>
         </OverlayView>
        {this.state.locationList.map((entry,index) => (
           <OverlayView
           position={{
                lat: entry.attributes.latitude,
                lng: entry.attributes.longitude,
              }}
           mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} 
         >
           <div
             style={{
               position: "absolute",
               transform: "translate(-50%, -50%)",
               backgroundColor: "#FFFDE7", 
              width: "60px",
            height: "60px",
               backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "50%", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
             }}
           >
             <img
               src= {entry.attributes.icon} 
               alt="Custom Marker"
               style={{
                 width: "20px",
                 height: "20px",
                 objectFit: "cover",
                
               }}
             />
           </div>
         </OverlayView>
        
        ))}
      </GoogleMap>
      </Box>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  landingPageView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position:"relative",
  },
  landingPageText: {
    fontSize: 42,
    letterSpacing: 2,
    fontWeight: "bold",
    color: "#323441",
    marginTop: 15
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  input: {
   
  },
}
  // Customizable Area End
